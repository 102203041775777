import * as Yup from 'yup';

export const signInValidationSchema = {
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  password: Yup.string()
    .min(6, 'Must be 6 characters or more')
    .required('Password is required.')
};
export const forgotPasswordValidationSchema = {
  email: Yup.string().email('Invalid email address.').required('Email is required.')
};
export const resetPasswordValidationSchema = {
  password: Yup.string()
    .required('Password is required.')
    .min(6, 'Password should be at least 6 characters.')
};

export const verifyEmailValidationSchema = {
  email: Yup.string().email().required('Email is required.')
};

export const userProfileValidationSchema = {
  firstname: Yup.string().required('first name is required.'),
  lastname: Yup.string().required('last name is required.'),
  phone: Yup.string()
    .required('This field is reuired')
    // .min(11, 'Your Phone Number must be at least 11 digits')
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g,
      'Should be a valid Phone Number'
    ),
  password: Yup.string().required('password is required.')
};

export const createClientValidationSchema = {
  // email: Yup.string().email().required('Email is required.'),
  company_name: Yup.string().required('Company name is required'),
  // admin_firstname: Yup.string().required('Admin Firstname is required'),
  // admin_lastname: Yup.string().required('Admin Lastname is required'),
  verificationTypes: Yup.array().test(
    'Check Selected',
    'Please select a verification type',
    (value) => {
      return value.length > 0;
    }
  )
};

export const createUserValidationSchema = {
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  phone: Yup.string().required('Phone number is required')
  // password: Yup.string().min(6).required('Password is required')
};

export const createInstitutionValidationSchema = {
  name: Yup.string().required('Institution name is required'),
  email: Yup.string().email().required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  location: Yup.string().required('Location is required')
};

export const adminValidationSchema = {
  firstname: Yup.string().required('Admin Firstname is required'),
  lastname: Yup.string().required('Admin Lastname is required'),
  email: Yup.string().email().required('Email is Required')
};
export const createCandidateValidationSchema = {
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  address: Yup.string().required('Address is required'),
  batch: Yup.string().required('Please select a batch')
};

export const academicValidationSchema = Yup.object().shape({
  educationDetails: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when('isForeignUniversity', {
        is: false,
        then: Yup.string().required('Institution name is required')
      }),
      foreignUniversityName: Yup.string().when('isForeignUniversity', {
        is: true,
        then: Yup.string().required('Foreign Institution name is required')
      }),
      degree: Yup.string().required('Qualification is required'),
      major: Yup.string().required('Course of Study is required'),
      grade: Yup.string().required('Grade is required'),
      location: Yup.string().required('Location is required'),
      yearStarted: Yup.number().required('Year Started is required'),
      yearCompleted: Yup.number().required('Year Completed is required'),
      cert: Yup.mixed().required('Please provide the certificate')
    })
  )
});

export const olevelValidationSchema = Yup.object().shape({
  olevelDetails: Yup.array().of(
    Yup.object().shape({
      examName: Yup.string().required('Examination name is required'),
      type: Yup.string().required('School type is required'),
      year: Yup.string().required('Please provide the Examination Year'),
      certificate: Yup.mixed().required('Please provide the certificate')
    })
  )
});

export const verificationValidationSchema = {
  verificationTypes: Yup.array().test(
    'Check Selected',
    'Please select a verification type',
    (value) => {
      return value.length > 0;
    }
  )
};

export const referenceValidationSchema = Yup.object().shape({
  referenceDetails: Yup.array().of(
    Yup.object().shape({
      referenceName: Yup.string().required('Reference name is required'),
      email: Yup.string().required('Email is required'),
      officialEmail: Yup.string().required('Offical Email is required'),
      phone: Yup.string().required('Phone number is required'),
      address: Yup.string().required('Address is required'),
      officeAddress: Yup.string().required('Offical Address is required')
    })
  )
});
export const guarantorValidationSchema = Yup.object().shape({
  guarantorDetails: Yup.array().of(
    Yup.object().shape({
      guarantorName: Yup.string().required('Guarantor name is required'),
      email: Yup.string().required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
      address: Yup.string().required('Address is required'),
      organization: Yup.string().required('Organization is required'),
      role: Yup.string().required('Role is required'),
      officialEmail: Yup.string().required('Official Email is required'),
      officialPhone: Yup.string().required('Official Phone number is required'),
      officialAddress: Yup.string().required('Official Address is required'),
      upload: Yup.mixed().required('Please provide the guarantor form')
    })
  )
});

export const nyscValidationSchema = {
  showDate: Yup.boolean(),
  callNumber: Yup.string().required('Call Number is required'),
  certificateNumber: Yup.string().required('Certificate Number is required'),
  certificate_type: Yup.string().required('Certificate type is required'),
  // dateStarted: Yup.string().required('Date Started is required'),
  // dateCompleted: Yup.string().required('Date Started is required'),
  dateStarted: Yup.string().when('showDate', {
    is: true,
    then: Yup.string().required('Start date is required')
  }),
  dateCompleted: Yup.string().when('showDate', {
    is: true,
    then: Yup.string().required('Date of completion is required')
  })
};

export const passwordValidationSchema = {
  old_password: Yup.string().required('Old Password is required'),
  new_password: Yup.string()
    .required('New Password is required')
    .min(6, 'Must be 6 characters or more')
};

export const professionalValidationSchema = Yup.object().shape({
  professionalDetails: Yup.array().of(
    Yup.object().shape({
      awardingBody: Yup.string().required('Awarding Body is required'),
      certificateName: Yup.string().required('Certificate Name is required'),
      certificateNumber: Yup.string().required('Certificate Number is required'),
      dateAwarded: Yup.string().required('Date Awarded is required'),
      upload: Yup.mixed().required('Please provide the professional certificate')
    })
  )
});

export const criminalValidationSchema = {
  date: Yup.string().required('Date of fingerprint capture is required'),
  venue: Yup.string().required('Venue of fingerprint capture is required')
};

export const addressValidationSchema = {
  address: Yup.string().required('Address is required'),
  busStop: Yup.string().required('Nearest Busstop is required'),
  landmark: Yup.string().required('Nearest Landmark is required'),
  city: Yup.string().required('City is required'),
  localGovt: Yup.string().required('Local Government is required'),
  state: Yup.string().required('State is required')
};

export const creditValidationSchema = {
  bvn: Yup.string().required('BVN is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  dob: Yup.string().required('Date of brith is required')
};

export const employerValidationSchema = Yup.object().shape({
  employerDetails: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Employer Name is required'),
      email: Yup.string().required('Email is required'),
      address: Yup.string().required('Address is required'),
      phone: Yup.string().required('Phone Number is required'),
      contactPerson: Yup.string().required('contact Person is required'),
      contactPersonRole: Yup.string().required('contact Person Role is required')
    })
  )
});

export const noteValidationSchema = Yup.object({
  note: Yup.string().required('Please add a note')
});
export const singleAcademicValidationSchema = Yup.object({
  name: Yup.string().required('Institution name is required'),
  degree: Yup.string().required('Qualification is required'),
  major: Yup.string().required('Course of Study is required'),
  grade: Yup.string().required('Grade is required'),
  location: Yup.string().required('Location is required'),
  yearStarted: Yup.number().required('Year Started is required'),
  yearCompleted: Yup.number().required('Year Completed is required'),
  cert: Yup.mixed().required('Please provide the certificate')
});
export const singleReferenceValidationSchema = Yup.object({
  referenceName: Yup.string().required('Reference name is required'),
  email: Yup.string().required('Email is required'),
  officialEmail: Yup.string().required('Offical Email is required'),
  phone: Yup.string().required('Phone number is required'),
  address: Yup.string().required('Address is required'),
  officeAddress: Yup.string().required('Offical Address is required')
});
export const singleGuarantorValidationSchema = Yup.object({
  guarantorName: Yup.string().required('Guarantor name is required'),
  email: Yup.string().required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  address: Yup.string().required('Address is required'),
  organization: Yup.string().required('Organization is required'),
  role: Yup.string().required('Role is required'),
  officialEmail: Yup.string().required('Official Email is required'),
  officialPhone: Yup.string().required('Official Phone number is required'),
  officialAddress: Yup.string().required('Official Address is required'),
  upload: Yup.mixed().required('Please provide the guarantor form')
});
export const singleEmployerValidationSchema = Yup.object({
  name: Yup.string().required('Employer Name is required'),
  email: Yup.string().required('Email is required'),
  address: Yup.string().required('Address is required'),
  phone: Yup.string().required('Phone Number is required'),
  contactPerson: Yup.string().required('contact Person is required'),
  contactPersonRole: Yup.string().required('contact Person Role is required')
});

export const singleOlevelValidationSchema = Yup.object().shape({
  examName: Yup.string().required('Examination name is required'),
  type: Yup.string().required('School type is required'),
  year: Yup.string().required('Please provide the Examination Year'),
  certificate: Yup.mixed().required('Please provide the certificate')
});
export const singleProfessionalValidationSchema = Yup.object().shape({
  awardingBody: Yup.string().required('Awarding Body is required'),
  certificateName: Yup.string().required('Certificate Name is required'),
  certificateNumber: Yup.string().required('Certificate Number is required'),
  dateAwarded: Yup.string().required('Date Awarded is required'),
  upload: Yup.mixed().required('Please provide the professional certificate')
});
