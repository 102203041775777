import gql from 'graphql-tag';

export const GET_CANDIDATE_VERIFICATION_REQUEST = gql`
  query verificationRequest($id: UUID!) {
    verificationRequest(id: $id) {
      id
      allChecksCommenced
      candidate {
        id
        firstname
        lastname
        email
        passportPhoto
      }
      client {
        id
        name
        clientUserList {
          email
          roles
        }
      }
      requestTypes
      consentRequest {
        id
        file
      }
      socialMediaRequest {
        id
        url
        handle
        status
      }
      creditRequest {
        id
        bvn
        phoneNumber
        dob
        meansOfIdentification
        idNumber
        idFront
        idBack
        status
        confirmationDocs
      }
      olevelRequest {
        id
        certificate
        confirmationDocs
        examName
        examNumber
        type
        year
        status
      }
      acadRequest {
        nameOfInstitution
        isForeign
        qualification
        yearCompleted
        yearStarted
        grade
        location
        courseOfStudy
        id
        upload
        confirmationDocs
        status
        consent
      }
      refRequest {
        id
        name
        email
        officialEmail
        phone
        address
        status
        officeAddress
        confirmationDocs
      }
      addressRequest {
        address
        nearestBusStop
        nearestLandmark
        state
        city
        localGovernment
        phoneNumber
        contactPerson
        id
        status
        confirmationDocs
      }
      profRequest {
        awardBody
        certificateName
        certificateNumber
        consent
        upload
        dateAwarded
        id
        confirmationDocs
        status
      }
      criminalRequest {
        fingerprintCapturingDate
        fingerprintCapturingVenue
        confirmationDocs
        passport
        id
        status
        race
      }
      nyscRequest {
        callNumber
        certificateNumber
        certificateType
        confirmationDocs
        upload
        dateCompleted
        dateStarted
        id
        status
      }
      guarantorRequest {
        id
        address
        officialEmail
        officialPhone
        officialAddress
        status
        role
        phone
        name
        upload
        confirmationDocs
        email
        organization
      }
      employeeRequest {
        employerName
        contactPerson
        contactPersonRole
        phone
        status
        consent
        confirmationDocs
        id
        address
        email
      }
    }
  }
`;

export const GET_INSTITUTION_LIST = gql`
  query institutionList {
    institutionList {
      totalCount
      results {
        createdAt
        email
        id
        location
        nameOfInstitution
        phone
        updatedAt
        upload
      }
    }
  }
`;
export const GET_REPORTING_INFO = gql`
  query genarateReport($candidates: [UUID]!, $startDate: String, $endDate: String) {
    report(candidates: $candidates, startDate: $startDate, endDate: $endDate) {
      results {
        requests {
          olevel {
            status
            examName
            remarkStatus
          }
          academic {
            id
            status
            nameOfInstitution
            remarkStatus
            dateConcluded
          }
          reference {
            id
            status
            name
            remarkStatus
            dateConcluded
          }
          address {
            id
            status
            remarkStatus
            dateConcluded
          }
          professional {
            id
            status
            awardBody
            remarkStatus
            dateConcluded
          }
          employment {
            id
            status
            employerName
            remarkStatus
            dateConcluded
          }
          criminal {
            id
            status
            remarkStatus
            dateConcluded
          }
          nysc {
            id
            status
            remarkStatus
            dateConcluded
          }
          guarantor {
            id
            status
            name
            remarkStatus
            dateConcluded
          }
        }
        candidate {
          firstname
          lastname
        }
      }
    }
  }
`;
// export const GET_REPORTING_INFO = gql`
//   query genarateReport($candidates: [UUID]!, $startDate: String, $endDate: String) {
//     report(candidates: $candidates, startDate: $startDate, endDate: $endDate) {
//       results {
//         requests {
//           id
//           olevelRequest {
//             id
//             status
//             examName
//           }
//           acadRequest {
//             id
//             status
//             nameOfInstitution
//             remarkStatus
//           }
//           refRequest {
//             id
//             status
//             name
//             remarkStatus
//           }
//           addressRequest {
//             id
//             status
//             remarkStatus
//           }
//           profRequest {
//             id
//             status
//             awardBody
//             remarkStatus
//           }
//           criminalRequest {
//             id
//             status
//             remarkStatus
//           }
//           nyscRequest {
//             id
//             status
//             remarkStatus
//           }
//           guarantorRequest {
//             id
//             status
//             name
//             remarkStatus
//           }
//           employeeRequest {
//             id
//             status
//             employerName
//             remarkStatus
//           }
//         }
//         candidate {
//           firstname
//           lastname
//         }
//       }
//     }
//   }
// `;
